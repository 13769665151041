import AppRoutes from 'Routes/Routes';
import { getRouteConfig } from 'Routes/layoutRouteConfig';
import './style.scss';
function App() {
  return (
    <>
      <AppRoutes routesConfig={getRouteConfig()} />
    </>
  );
}

export default App;
