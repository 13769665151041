// import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import BackdropLoader from 'Components/BackdropLoader';
import useAuth from 'Hooks/useAuth';
// import useConfig from 'Hooks/useConfig';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';

const PersistLogin = () => {
  const { isAuthStateLoading, isLoggedIn } = useAuth();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded && !isAuthStateLoading) {
      setIsLoaded(true);
    }
  }, [isAuthStateLoading, isLoggedIn, isLoaded]);

  return isLoaded ? <Outlet /> : <BackdropLoader />;
};

export default PersistLogin;
