import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { selectCurrentAuthState, unsetCredentials, setCredentials } from 'redux/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext } from 'Providers/AuthProvider';
import useConfig from './useConfig';
import auth0, {
  Auth0DecodedHash,
  Auth0ParseHashError,
  PasswordlessStartOptions,
  PasswordlessVerifyOptions
} from 'auth0-js';
import { useSnackbar } from 'notistack';
import {
  COOKIES_ERROR,
  MAXIMUM_LIMIT_ERROR_MESSAGE,
  UPDATED_MAXIMUM_LIMIT_ERROR_MESSAGE
} from 'constants/common';
const Citizenregex = /\b(sms)\b/i;

type ErrorObject = { statusCode: number; name: string; message: string };

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

/**
 * Type predicate to narrow an unknown data property in `FetchBaseQueryError`
 */
export function hasErrorObject(obj: unknown): obj is { error: ErrorObject } {
  return typeof obj === 'object' && obj !== null && 'error' in obj;
}

/**
 * Custom hook for handling authentication-related functionality.
 * Manages login, logout, and provides necessary data and loading states.
 */
export default function useAuth() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const authData = useAppSelector(selectCurrentAuthState);
  const [hasShownWarning, setHasShownWarning] = useState(false);
  const {
    config: { auth0Domain, auth0NotifyClientId, auth0Audience }
  } = useConfig();
  const {
    token,
    setToken,
    isAuthenticated,
    isLoading,
    setUser,
    user,
    setIsLoading,
    setIsAuthenticated,
    isCitizen,
    setIsCitizen
  } = useContext(AuthContext);

  const auth0Client = useMemo(() => {
    if (auth0NotifyClientId && auth0Domain && auth0Audience) {
      return new auth0.WebAuth({
        domain: auth0Domain,
        clientID: auth0NotifyClientId,
        responseType: 'token id_token',
        redirectUri: `${window.location.origin}/callback`,
        scope: 'openid profile admin:on read:users create:users update:users',
        audience: auth0Audience
      });
    }
    return null;
  }, [auth0NotifyClientId, auth0Domain, auth0Audience]);

  const handlePasswordlessLogin = (username: string, callback: () => void, connection?: string) => {
    if (!auth0Client) return;
    setIsLoading(true);
    const options: PasswordlessStartOptions = {
      connection: connection ?? 'email',
      send: 'code'
    };
    if (connection === 'sms') {
      options.phoneNumber = username;
    } else {
      options.email = username;
    }

    auth0Client.passwordlessStart(options, (err, res) => {
      setIsLoading(false);
      if (err) {
        console.error(err);
        enqueueSnackbar(err.description, { variant: 'error' });
      } else {
        enqueueSnackbar(`OTP has been sent to your ${connection === 'sms' ? 'phone' : 'email'}`, {
          variant: 'success'
        });
        callback();
      }
    });
  };

  const handleOtpVerify = (
    username: string,
    code: string,
    callback: (email: string, err: auth0.Auth0Error | null) => void,
    connection?: string
  ) => {
    if (!auth0Client) return;
    setIsLoading(true);
    const options: PasswordlessVerifyOptions = {
      connection: connection ?? 'email',
      verificationCode: code
    };
    if (connection === 'sms') {
      options.phoneNumber = username;
    } else {
      options.email = username;
    }
    auth0Client.passwordlessVerify(options, async (err, res) => {
      setIsLoading(false);
      if (err) {
        let errorMessage = err.original?.response?.body?.message ?? err.description;

        if (errorMessage === MAXIMUM_LIMIT_ERROR_MESSAGE) {
          errorMessage = UPDATED_MAXIMUM_LIMIT_ERROR_MESSAGE;
        }
        enqueueSnackbar(errorMessage, { variant: 'error' });
        callback(username, err);
        console.log(err);
      } else {
        callback(username, null);
      }
    });
  };

  const getUser = useCallback(
    (hashResponse: auth0.Auth0DecodedHash) => {
      if (!auth0Client) {
        return;
      }
      auth0Client.client.userInfo(hashResponse?.accessToken ?? '', (usrError, user) => {
        if (user) {
          setIsAuthenticated(true);
          setIsLoading(false);
          setIsCitizen(!!Citizenregex.test(user?.sub ?? ''));
          setUser(user);
        } else {
          console.log(usrError);
        }
      });
    },
    [auth0Client, setIsAuthenticated, setIsCitizen, setIsLoading, setUser]
  );

  const handleAuthenticate = () => {
    const hash = window.location.hash;
    if (!auth0Client || !hash) return;

    setIsLoading(true);

    auth0Client.parseHash({ hash }, (err, res) => {
      if (err) {
        handleError(err);
        return;
      }

      if (res) {
        handleSuccess(res);
      }
    });
  };

  const handleError = (err: Auth0ParseHashError) => {
    console.error(err);
    const error = err.description || err.error || err.errorDescription || '';
    if (error === 'invalid_token') {
      if (!hasShownWarning) {
        enqueueSnackbar(COOKIES_ERROR, { variant: 'warning' });
        setHasShownWarning(true);
      }
    } else {
      setHasShownWarning(false);
      enqueueSnackbar(error, { variant: 'error' });
    }
  };

  const handleSuccess = (res: Auth0DecodedHash) => {
    setCredentials({
      accessToken: res.accessToken || '',
      refreshToken: res.refreshToken || '',
      expires: res.expiresIn || 0
    });
    getUser(res);
  };

  const logout = async (returnTo?: string) => {
    try {
      auth0Client?.logout({ returnTo: returnTo ?? `${window.location.origin}/login` });
      setToken(null);
      dispatch(unsetCredentials());
    } catch (err) {
      console.error(err); // NOSONAR
    }
  };

  useEffect(() => {
    setIsLoading(true);
    auth0Client?.checkSession(
      { mode: 'login', domain: auth0Domain, clientID: auth0NotifyClientId },
      (err, res) => {
        if (!err && !user) {
          getUser(res);
        } else {
          setIsLoading(false);
        }
      }
    );
  }, [auth0Client, auth0Domain, auth0NotifyClientId, getUser, setIsLoading, user]);

  return {
    isLoggedIn: isAuthenticated,
    authData,
    logout,
    loginLoading: !isAuthenticated && isLoading,
    logoutLoading: isAuthenticated && isLoading,
    isAuthStateLoading: isLoading,
    token,
    setToken,
    handlePasswordlessLogin,
    handleOtpVerify,
    handleAuthenticate,
    user,
    isCitizen
  };
}
