import { AuthRedirectWrapper } from 'Components/AuthRedirectWrapper';
import { ProtectedRouteWrapper } from 'Components/ProtectedRouteWrapper';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router';
import PersistLogin from './PersistLogin';
const Login = lazy(() => import('../Pages/Login'));
const Callback = lazy(() => import('../Pages/AuthCallback'));
const Mainlayout = lazy(() => import('Layouts/MainLayout/Mainlayout'));
const CitizenLogin = lazy(() => import('../Pages/Citizen/CitizenLogin'));

const newRouteObject: RouteObject[] = [
  {
    element: <PersistLogin />,
    children: [
      {
        path: '/login',
        element: (
          <AuthRedirectWrapper>
            <Login />
          </AuthRedirectWrapper>
        )
      },
      {
        path: '/callback',
        element: (
          <AuthRedirectWrapper>
            <Callback />
          </AuthRedirectWrapper>
        )
      },
      {
        path: '/citizen/login',
        element: <CitizenLogin />
      },
      {
        path: '/',
        element: <Navigate to={'/home'} replace />
      },
      {
        path: '/*',
        element: (
          <ProtectedRouteWrapper>
            <Mainlayout />
          </ProtectedRouteWrapper>
        )
      }
    ]
  }
];

/**
 * Routes that require authorization are protected using the ProtectedRouteWrapper component (default authorization is user authentication).
 * Authenticated users are redirected to the home page using the AuthRedirectWrapper component.
 *
 * @returns An array of RouteObject defining the routes of the application.
 */
export const getRouteConfig = (): RouteObject[] => {
  return newRouteObject;
};
