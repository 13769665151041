import useAuth from 'Hooks/useAuth';
import { Navigate, useLocation } from 'react-router-dom';

export interface ProtectedRouteWrapperProps {
  children: JSX.Element;
  isAuthorized?: (...args: any[]) => boolean; // NOSONAR
}

/**
 * Wrapper component for protected routes.
 * Authenticated users are rendered the requested page while unauthenticated users are redirected to the login page.
 *
 * @param children - JSX element that will be displayed if the user is authenticated.
 * @param isAuthorized - A function that checks the authorization of a user. It should return `true` if the user is authenticated, and `false` otherwise.
 *                       By default, it uses `authorizationFunctions.isAuthenticated`.
 * @returns The protected route component.
 */
export const ProtectedRouteWrapper = ({ children, isAuthorized }: ProtectedRouteWrapperProps) => {
  const location = useLocation();
  const { isLoggedIn, isAuthStateLoading } = useAuth();
  let authorized = false;
  if (!isLoggedIn && isAuthorized && typeof isAuthorized === 'function') {
    authorized = isAuthorized();
  }
  if (isLoggedIn || authorized) {
    return children;
  } else if (!(isLoggedIn || authorized) && isAuthStateLoading) {
    return null;
  }
  return <Navigate to={'/login'} state={{ from: location }} replace />;
};
