import { ReactNode, createContext, useState } from 'react';
import auth0 from 'auth0-js';

const defaultAuthContextValue: {
  token: string | null;
  setToken: (value: string | null) => void;
  user: auth0.AdfsUserProfile | null;
  setUser: (user: auth0.AdfsUserProfile | null) => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  isCitizen: boolean;
  setIsCitizen: (value: boolean) => void;
  account: string | null;
  setAccount: (value: string | null) => void;
} = {
  token: null,
  setToken: (value: string | null) => {},
  user: null,
  setUser: (value: auth0.AdfsUserProfile | null) => {},
  isAuthenticated: false,
  setIsAuthenticated: (value: boolean) => {},
  isLoading: false,
  setIsLoading: (value: boolean) => {},
  isCitizen: false,
  setIsCitizen: (value: boolean) => {},
  account: null,
  setAccount: (value: string | null) => {}
};

export const AuthContext = createContext(defaultAuthContextValue);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<string | null>(null);
  const [account, setAccount] = useState<string | null>(null);
  const [user, setUser] = useState<auth0.AdfsUserProfile | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCitizen, setIsCitizen] = useState<boolean>(false);

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        isAuthenticated,
        setIsAuthenticated,
        isLoading,
        setIsLoading,
        user,
        setUser,
        isCitizen,
        setIsCitizen,
        account,
        setAccount
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
